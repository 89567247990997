import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Projects } from './pages/Projects';

import {NavBar} from './components/nav-bar';
import './App.css';

const App = () => {
  return (
    <>
      <div className='header'>
        <NavBar/>
      </div>
      <div className="content">
        <Routes>
          <Route exact path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/projects' element={<Projects/>} />
        </Routes>
      </div>
    </>
  );
};


export default App;